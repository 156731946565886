"use client";

import { Button, Input, PasswordInput } from "@/components/custom";
import Logo from "@/components/svg/logo";
import { Login } from "@/lib/actions";
import Link from "next/link";
import { FaEnvelope, FaLock, FaEye } from "react-icons/fa6";
import { useRouter } from "next/navigation";
import { object, set, z } from "zod";
import { useState, useTransition } from "react";
import Swal from "@/components/modal";
import AlertSvg from "@/components/svg/alert-svg";
import Image from "next/image";

const schema = z.object({
  email: z.string({ message: "This field is required" }).email({ message: "Invalid email address" }),
  password: z.string({ message: "This field is required" }).min(8, { message: "Must be consists 8 to 20 characters" }),
});

export default function LoginPage() {
  const router = useRouter();
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const [pending, startTransition] = useTransition();
  const HandleSubmit =  (e: FormData) => {
    startTransition(async () => { try {
      const payload = await schema.safeParseAsync({
        email: e.get("email") as string,
        password: e.get("password") as string,
      });

      if (!payload.success) {
        const err = payload.error.flatten().fieldErrors as Record<string, string[]>;
        const newErrors: Record<string, string> = {};
        Object.keys(err).forEach((key) => {
          newErrors[key] = err[key]?.[0] as string;
        });
        setErrors(newErrors);
        console.log(errors);
        return;
      }

      const res = (await Login({
        email: e.get("email") as string,
        password: e.get("password") as string,
      })) as Record<string, string>;
      console.log(res);
      if ("errors" in res) {
        setErrors({ email: " ", password: " " });
        Swal.fire({
          iconHtml: <AlertSvg />,
          showCloseButton: true,
          html: (
            <div className="size-full space-y-4">
              <div className="text-lg font-bold">Oops</div>
              <p className="text-sm text-gray-400">Invalid email or password</p>
            </div>
          ),
        });
        return;
      }

      router.push("/");
    } catch (error) {
      console.log(error);
      setErrors({ email: " ", password: " " });
      Swal.fire({
        iconHtml: <AlertSvg />,
        showCloseButton: true,
        html: (
          <div className="size-full space-y-4">
            <div className="text-lg font-bold">Oops</div>
            <p className="text-sm text-gray-400">Invalid email or password</p>
          </div>
        ),
      });
    }});
  };

  return (
    <form action={HandleSubmit} className="flex w-full flex-col items-center gap-4">
      <Image className="mx-auto mt-4" src="/assets/img/logo.webp" alt="" width={200} height={80} />
      <div className="w-full text-center text-xl font-bold">Welcome Back!</div>
      <Input
        onFocus={() => {
          setErrors({});
        }}
        name="email"
        label="Email"
        placeholder="Your Email"
        isInvalid={!!errors.email}
        errorMessage={errors.email}
        startContent={<FaEnvelope color="#FFCA05" size={16} />}
      />
      <PasswordInput
        onFocus={() => {
          setErrors({});
        }}
        name="password"
        label="Password"
        isInvalid={!!errors.password}
        errorMessage={errors.password}
        placeholder="Password"
      />
      <div className="flex justify-center">
        <Link href={"/password/forgot"} className="text-base font-bold text-[#977200] underline">
          Forgot Password?
        </Link>
      </div>
      <div className="my-4 w-full">
        <Button isDisabled={pending} type="submit">Login</Button>
      </div>
      <div className="mt-4 flex justify-center">
        Don't have an account?
        <Link href={"/register"} className="ml-1 inline-block text-base font-bold text-[#977200] underline">
          Register
        </Link>
      </div>
    </form>
  );
}
