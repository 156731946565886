export default function AlertSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none">
      <g clipPath="url(#clip0_211_4120)">
        <path
          d="M60.0037 120.007C93.1428 120.007 120.007 93.1428 120.007 60.0037C120.007 26.8645 93.1428 0 60.0037 0C26.8645 0 0 26.8645 0 60.0037C0 93.1428 26.8645 120.007 60.0037 120.007Z"
          fill="white"
        />
        <path
          d="M114.829 59.9964C114.829 65.6631 113.968 71.1326 112.376 76.2734C112.273 76.5947 112.171 76.9233 112.062 77.2373C111.835 77.9238 111.594 78.6102 111.331 79.282C111.134 79.8078 110.93 80.3335 110.703 80.852C104.445 96.0921 91.4908 107.893 75.4986 112.588C75.0532 112.72 74.6077 112.851 74.1623 112.961C73.9067 113.034 73.6511 113.099 73.3882 113.165C73.0888 113.245 72.7821 113.311 72.4827 113.384C68.4664 114.326 64.2968 114.815 59.9957 114.815C29.7127 114.815 5.16211 90.2721 5.16211 59.9818C5.16211 29.6916 29.72 5.17017 60.003 5.17017C90.2859 5.17017 114.829 29.7208 114.829 60.0037V59.9964Z"
          fill="#FFE179"
        />
        <path
          d="M60.003 107.827C33.6341 107.827 12.1797 86.3726 12.1797 60.0037C12.1797 33.6348 33.6268 12.1731 60.003 12.1731C86.3792 12.1731 107.826 33.6275 107.826 59.9964C107.826 86.3653 86.3719 107.82 60.003 107.82V107.827ZM60.003 16.0945C35.7956 16.0945 16.1011 35.789 16.1011 59.9964C16.1011 84.2038 35.7956 103.898 60.003 103.898C84.2104 103.898 103.905 84.2038 103.905 59.9964C103.905 35.789 84.2104 16.0945 60.003 16.0945Z"
          fill="white"
        />
        <g clipPath="url(#clip1_211_4120)">
          <path
            d="M59.9777 67.6765C59.0157 67.6765 58.1428 67.2337 57.6426 66.4915C57.3721 66.0897 57.3401 65.5783 57.3027 64.9867C57.2956 64.8762 57.2885 64.7666 57.2805 64.657C57.1505 62.9553 57.0366 61.2214 56.9272 59.5446C56.8969 59.0813 56.8667 58.618 56.8355 58.1547C56.7964 57.5587 56.759 56.9626 56.7216 56.3666C56.6673 55.4997 56.6122 54.6025 56.549 53.7186C56.4849 52.8179 56.4155 51.9037 56.3488 51.0199C56.2429 49.6282 56.1334 48.1884 56.0435 46.7744C55.9901 45.936 55.9394 45.1502 56.193 44.4472C56.6718 43.1161 57.5848 42.3651 59.0656 42.0844C59.4544 42.0104 59.6644 41.9998 59.7552 41.9998C59.7899 41.9998 59.8095 42.0015 59.8166 42.0024L59.8656 42.0078H59.9652C61.6765 42.0078 62.7621 42.5281 63.4847 43.6953C63.9038 44.3715 64.0649 45.1048 63.9777 45.936C63.8798 46.8716 63.8291 47.816 63.781 48.7292L63.7721 48.9056C63.6582 51.0493 63.4891 53.2402 63.3396 55.1255C63.2658 56.0538 63.2079 56.9947 63.151 57.9044C63.1198 58.3989 63.0896 58.8942 63.0567 59.3887C63.0202 59.9313 62.9846 60.4748 62.949 61.0174C62.8431 62.6301 62.7336 64.297 62.6046 65.932C62.5743 66.3124 62.335 66.659 61.8269 67.0564C61.3161 67.4564 60.6602 67.6765 59.9795 67.6765H59.9777Z"
            fill="#212121"
          />
          <path
            d="M59.9857 78.9997C59.1057 78.9997 58.2265 78.6184 57.5724 77.9528C56.921 77.29 56.5713 76.4239 56.5864 75.5143C56.6176 73.6655 58.1597 72.1411 60.024 72.1152H60.0614C60.9566 72.1152 61.8954 72.5482 62.5726 73.2744C63.1733 73.9185 63.4758 74.691 63.4233 75.4492L63.4198 75.4956L63.4216 75.5419C63.4527 76.4177 63.1208 77.2632 62.4881 77.9252C61.8313 78.6113 60.9495 78.9926 60.0071 78.9988H59.9866L59.9857 78.9997Z"
            fill="#212121"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_211_4120">
          <rect width="120" height="120" fill="white" />
        </clipPath>
        <clipPath id="clip1_211_4120">
          <rect width="8" height="37" fill="white" transform="translate(56 41.9998)" />
        </clipPath>
      </defs>
    </svg>
  );
}
